import React, {useState} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import {Badge, Button, Input, Table} from 'antd';
import {Link} from 'react-router-dom/cjs/react-router-dom.min';
import {subscriptionItemStatusColor} from '../../helpers/params';

function RequireChange({id, onAction}) {
  const [showSelector, setShowSelector] = useState(false);
  const [reason, setReason] = useState('');
  return showSelector ? (
    <div className='flex gap-1 flex-col'>
      <Input
        value={reason}
        placeholder='Razón del cambio...'
        onChange={(e) => setReason(e.target.value)}
      />
      <Button
        disabled={!reason}
        onClick={() => onAction(id, 'mark_require_change', {reason})}
      >
        Marcar
      </Button>
    </div>
  ) : (
    <Button onClick={() => setShowSelector(true)}>
      Marcar como Requiere Cambio
    </Button>
  );
}

RequireChange.propTypes = {
  id: PropTypes.number.isRequired,
  onAction: PropTypes.func.isRequired,
};

const SubscriptionItemsTable = ({items, onAction, manageItem}) => {
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'QR',
      dataIndex: 'item',
      key: 'qr',
      render: (v, r) => (
        <>
          <Link to={`/device/${v.qr}`}>{v.qr}</Link>{' '}
          {r.status === 'ASSIGNED' && <>({v.status_tr})</>}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_tr',
      align: 'center',
      key: 'status_tr',
      render: (v, r) => (
        <div>
          <Badge
            count={v}
            showZero
            color={subscriptionItemStatusColor[r.status]}
          />
          {['REQUIRE_CHANGE', 'REQUIRE_CHANGE_READY_FOR_SHIPPING'].includes(
            r.status
          ) ? (
            <div className='text-xs mt-2'>
              Razón del cambio: {r.data.reason ?? 'Sin razón'}
            </div>
          ) : null}
        </div>
      ),
    },
    {
      title: 'Comienzo',
      dataIndex: 'start_at',
      key: 'start_at',
      render: (v) => (v ? moment.utc(v).local().format('YYYY-MM-DD') : '-'),
    },
    {
      title: 'Fin',
      dataIndex: 'end_at',
      key: 'end_at',
      render: (v) => (v ? moment.utc(v).local().format('YYYY-MM-DD') : '-'),
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'right',
      key: 'tools',
      render: (id, r) => (
        <>
          {manageItem &&
            ['REQUIRE_CHANGE', 'REQUIRE_CHANGE_READY_FOR_SHIPPING'].includes(
              r.status
            ) && (
              <div className='flex flex-col gap-4'>
                <Button onClick={() => onAction(id, 'mark_assigned')}>
                  Volver a Asignado
                </Button>
              </div>
            )}
          {manageItem && r.status === 'ASSIGNED' && (
            <div className='flex flex-col gap-4'>
              <Button onClick={() => onAction(id, 'mark_returned')}>
                Marcar como retornado
              </Button>
              <RequireChange id={id} onAction={onAction} />
              <Button danger onClick={() => onAction(id, 'mark_stolen')}>
                Marcar como ROBADO
              </Button>
            </div>
          )}
          {manageItem && r.status === 'PICKED' && (
            <Button onClick={() => onAction(id, 'mark_returned')}>
              Marcar como retornado
            </Button>
          )}
        </>
      ),
    },
  ].filter((x) => !!x);

  return (
    <Table
      size='small'
      pagination={{hideOnSinglePage: true, defaultPageSize: 20}}
      locale={{emptyText: 'Sin items asignados'}}
      columns={columns}
      dataSource={
        items
          ? items.map((d) => ({
              key: d.id,
              ...d,
            }))
          : []
      }
    />
  );
};

SubscriptionItemsTable.defaultProps = {
  items: [],
  onAction: () => {},
  manageItem: false,
};
SubscriptionItemsTable.propTypes = {
  items: PropTypes.array,
  onAction: PropTypes.func,
  manageItem: PropTypes.bool,
};

export default SubscriptionItemsTable;
