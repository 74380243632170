export const executeAndWaitResponse = async (retriever) => {
  try {
    const rsp = await retriever();
    const {data} = rsp;
    return {
      status: true,
      data,
    };
  } catch (e) {
    // console.log('e', e.response);
    if (e && e.response && e.response.status >= 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: rsp.msg,
          data: rsp ?? {},
        };
      }
    }
    const rsp = e.response?.data ?? {};
    return {
      status: false,
      msg: 'Error desconocido',
      data: rsp.data ?? {},
    };
  }
};

export default {
  executeAndWaitResponse,
};
