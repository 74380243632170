import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {Badge} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faPhone,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';
import {pointNamesByType, subscriptionStatusColor} from '../../helpers/params';

const SubscriptionResume = ({subscription, actions, children}) => (
  <div className={styles.subscription}>
    <div className={styles.info}>
      <div className={`flex gap-1 items-center ${styles.plan}`}>
        <div>
          <Link to={`/suscripcion/${subscription.id}`}>#{subscription.id}</Link>{' '}
          - <strong>{subscription.item_type}</strong>. Cantidad:
        </div>
        <strong className='flex gap-2 items-center'>
          <span style={{marginRight: 10}} className='flex gap-2 items-center'>
            <div>{subscription.qty}</div>
            {subscription.type === 'REQUIRE_CHANGE' ? (
              <div className='text-xxs text-red-500'>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className='mr-1'
                />
                Cambio de patín
              </div>
            ) : null}
          </span>
          {subscription.items
            ?.filter((x) => x.status === 'ASSIGNED')
            .map((item) => (
              <span style={{marginLeft: 10}}>QR: {item.item?.qr}</span>
            ))}
        </strong>
      </div>
      <div>
        <Badge
          count={subscription.status_tr}
          showZero
          color={subscriptionStatusColor[subscription.status]}
        />
      </div>
      <div className={styles.user}>
        <FontAwesomeIcon icon={faUser} style={{marginRight: 5}} />
        {subscription.user.name}

        <FontAwesomeIcon
          icon={faPhone}
          style={{marginRight: 5, marginLeft: 15}}
        />
        {subscription.user.phone}
      </div>
      <div className={styles.address}>
        {pointNamesByType[subscription.data?.shipping_point]}
        {' > '}
        {subscription.user?.data?.grin4uinfo
          ? // eslint-disable-next-line prefer-template
            `${subscription.user?.data?.grin4uinfo[subscription.data?.shipping_point + '_address']}, ${subscription.user?.data?.grin4uinfo[subscription.data?.shipping_point + '_district']}`
          : '-'}
      </div>
    </div>
    <div className={styles.actions}>{actions}</div>
    {children}
  </div>
);

SubscriptionResume.defaultProps = {
  actions: null,
  children: null,
};

SubscriptionResume.propTypes = {
  subscription: PropTypes.object.isRequired,
  actions: PropTypes.any,
  children: PropTypes.any,
};

export default SubscriptionResume;
