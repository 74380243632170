/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {Button, Input, Space, Checkbox, DatePicker} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell, faPlus, faSpinner} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as notesService from '../../services/notes';

import styles from './index.module.scss';
import Loading from '../../components/Loading';

dayjs.extend(utc);

const NotesListModule = ({user, itemType, itemId, predefinedNotes}) => {
  const [notes, setNotes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [createReminder, setCreateReminder] = useState(false);
  const [reminderDate, setReminderDate] = useState(null);
  const [note, setNote] = useState('');

  const loadNotes = async () => {
    setLoading(true);
    const rsp = await notesService.getForItem(itemType, itemId);
    if (rsp?.status) {
      setNotes(rsp.data);
    }
    setLoading(false);
  };
  const createNote = async (newNote = null) => {
    if (!newNote && !note) {
      return;
    }
    const sendNote = newNote || note;
    setSaving(true);
    const rsp = await notesService.create({
      user_id: user.id,
      item_type: itemType,
      item_id: itemId,
      remind_at:
        createReminder && reminderDate
          ? dayjs(reminderDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : null,
      note: sendNote,
    });
    if (rsp?.status) {
      setNotes((prevNotes) => [rsp.data, ...prevNotes]);
    }
    setSaving(false);
  };
  useEffect(() => {
    loadNotes();
  }, []);

  return (
    <div className={styles.notes}>
      <Space
        direction='vertical'
        size={10}
        style={{width: '100%', marginBottom: 20}}
      >
        <Input.TextArea
          placeholder='Nueva nota...'
          disabled={saving}
          width='100%'
          value={note}
          onChange={(e) => setNote(e.target.value)}
          style={{width: '100%', resize: 'none'}}
        />
        {predefinedNotes ? (
          <div className='flex gap-2 flex-wrap'>
            {predefinedNotes.map((predefinedNote) => (
              <button
                className='underline cursor-pointer text-xs'
                type='button'
                onClick={() => setNote(predefinedNote)}
              >
                <span>&quot;{predefinedNote}&quot;</span>
              </button>
            ))}
          </div>
        ) : null}
        <div>
          <Checkbox
            defaultValue={createReminder}
            onChange={(e) => {
              setCreateReminder(e.target.checked);
            }}
          >
            Crear recordatorio
          </Checkbox>
        </div>

        {createReminder && (
          <div>
            <DatePicker
              style={{width: '100%'}}
              onChange={(v) => setReminderDate(v)}
              format='YYYY-MM-DD'
            />
          </div>
        )}

        <Button onClick={() => createNote()} disabled={saving || !note}>
          {saving ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <div className='flex items-center gap-1'>
              <FontAwesomeIcon icon={faPlus} />
              <span>Agregar</span>
            </div>
          )}
        </Button>
      </Space>
      {loading ? (
        <div className='p-4 items-center justify-center grid h-20'>
          <Loading size='sm' styles={{padding: 0}} />
        </div>
      ) : !notes || notes.length === 0 ? (
        <div className={styles.noNotes}>No hay notas</div>
      ) : (
        <div className={styles.list}>
          {notes.map((e) => (
            <div className={styles.note}>
              <div className={styles.date}>
                <div className={styles.text}>{e.note}</div>
                {e.user?.name || 'Sin nombre'} - {e.user?.email}
                <div>
                  Creada:{' '}
                  {moment
                    .utc(e.created_at)
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss')}
                </div>
                {e.remind_at && (
                  <div>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{marginRight: 5, color: 'var(--mainColor)'}}
                    />
                    Recordatorio:{' '}
                    {moment.utc(e.remind_at).local().format('YYYY-MM-DD')} (
                    {moment.utc(e.remind_at).fromNow()})
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
NotesListModule.defaultProps = {
  predefinedNotes: null,
};

NotesListModule.propTypes = {
  user: PropTypes.object.isRequired,
  itemType: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  predefinedNotes: PropTypes.arrayOf(PropTypes.string),
};

export default NotesListModule;
